import { yupResolver } from '@hookform/resolvers/yup';
import { Loading } from 'components/loading';
import { SearchSelectInput } from 'components/search-selected-input';
import InputText from 'components/styled-input';
import { ORGANIZATION_NAME } from 'config/constants';
import {
  Container,
  EditButton,
  EditButtonContainer,
  InputBox,
  Labelinput,
} from 'features/admin/customerAnalysis/components/tabDetails/styles';
import { Authority } from 'model/enums/authority';
import { Mask } from 'model/enums/mask-types';
import { OrganizationName } from 'model/enums/organization-name';
import { Financing } from 'model/financing';
import { IfirstDueDateInDays } from 'model/landing-page';
import { useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { FiEdit, FiEdit2 } from 'react-icons/fi';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { IRootState } from 'reducer';
import financingService from 'services/financing-service';
import landingPageService from 'services/landing-page-service';
import StringUtils from 'shared/util/string-utils';
import { hasAuthorities } from 'shared/util/utils';
import { useTheme } from 'styled-components';
import * as yup from 'yup';

interface Schema {
  choosedValue: string;
  approvedValue: string;
  installmentValue: string;
  installmentsTotal: string;
  loanGracePeriod: string;
  financingTaxInitial: string;
  interestTaxReleased: string;
  financingTax: string;
  cet: string;
  masterRetentionValue: string;
}

export const TabDetails = () => {
  const { financingId } = useParams<{ financingId: string }>();
  const [financing, setFinancing] = useState<Financing>();
  const { color } = useTheme();
  const { t } = useTranslation();
  const [disabledToEdit, setDisabledToEdit] = useState(true);
  const [chosenLoanGracePeriod, setChosenLoanGracePeriod] = useState<string>();
  const [loading, setLoading] = useState(false);
  const [installmentRange, setInstallmentRange] = useState<{ min: number; max: number } | null>(null);
  const [hasError, setHasError] = useState(false);
  const account = useSelector((state: IRootState) => state.authentication.account);
  const [loanGracePeriodOptions, setLoanGracePeriodOptions] = useState<IfirstDueDateInDays[]>([]);

  useEffect(() => {
    if (financingId) {
      getFinancingById();
    }
  }, [financingId]);

  useEffect(() => {
    getOrganizationInstallmentsRange();
  }, []);

  useEffect(() => {
    if (hasError) {
      setTimeout(() => {
        setLoading(false);
        setHasError(false);
      }, 200);
    }
  }, [hasError]);

  const schema = yup.object().shape({
    choosedValue: yup.string(),
    approvedValue: yup.string(),
    installmentValue: yup.string(),
    installmentsTotal: yup.string(),
    loanGracePeriod: yup.string(),
    financingTaxInitial: yup.string(),
    financingTax: yup.string(),
    cet: yup.string(),
  });

  const methods = useForm<Schema>({
    resolver: yupResolver(schema),
  });

  const getOrganizationInstallmentsRange = async () => {
    const organization = await landingPageService.getSimulatorParameters();
    setInstallmentRange({ min: organization.minInstallment, max: organization.maxInstallment });
    Array.isArray(organization.firstDueDateInDays) && setLoanGracePeriodOptions(organization.firstDueDateInDays);
  };

  const getFinancingById = async () => {
    const financingRes = await financingService.getFinancingById(Number(financingId));
    setFinancing(financingRes);
  };

  const saveEdit = async (values: Schema) => {
    const interestTaxReleased = Number(values.interestTaxReleased.replace('% a.m', '').replace(',', '.').trim());
    const masterRetentionValue = values?.masterRetentionValue != null ? Number(values.masterRetentionValue?.replace('%', '').trim()) : 0;
    const approvedValue = Number(StringUtils.removeMoneyFormat(values.approvedValue));
    const firstDueDateInDaysId = Number(chosenLoanGracePeriod);
    const approvedTax = interestTaxReleased > 0 ? interestTaxReleased : Number(values.financingTaxInitial.replace('% a.m', '').trim());
    const installmentsTotal = Number(values.installmentsTotal.replace('parcelas', '').trim());

    if (installmentsTotal === 0) {
      methods.setValue('installmentsTotal', `${installmentsTotal} parcelas`);
    }

    if (approvedTax === 0) {
      setHasError(true);
      setLoading(true);
      return methods.setError('financingTaxInitial', { message: t('global.errorMessage.income') });
    }

    setLoading(true);

    if (financing?.id) {
      const payload: {
        id: number;
        approvedValue?: number;
        approvedTax: number;
        installmentsTotal?: number;
        firstDueDateInDaysId?: number;
        retentionPercentage?: number;
      } = {
        id: financing.id,
        approvedTax,
        ...(installmentsTotal !== 0 && { installmentsTotal }),
        ...(approvedValue !== 0 && { approvedValue }),
        ...(firstDueDateInDaysId && { firstDueDateInDaysId }),
        ...(masterRetentionValue !== 0 && { retentionPercentage: masterRetentionValue }),
      };

      await financingService.updateAdminFinancing(financing.id, payload);

      setLoading(false);
      getFinancingById();
      setDisabledToEdit(true);
    }
  };

  return (
    <Container>
      <FormProvider {...methods}>
        <InputBox>
          <InputText
            name="choosedValue"
            label={t('admin.customerAnalysis.tabDetails.choosedValue')}
            disabled
            defaultValue={StringUtils.moneyMaskPtbr(financing?.choosedValue ?? 0)}
            width={100}
          />
          <InputText
            name="approvedValue"
            label={t('admin.customerAnalysis.tabDetails.approvedValue')}
            disabled={disabledToEdit}
            defaultValue={StringUtils.moneyMaskPtbr(financing?.approvedValue ?? 0)}
            mask={disabledToEdit ? Mask.DEFAULT : Mask.NUMBERS}
            onBlur={() => {
              const value = methods.getValues('approvedValue');
              methods.setValue('approvedValue', StringUtils.moneyMaskPtbr(Number(value)));
            }}
            onFocus={() => {
              const value = methods.getValues('approvedValue');
              methods.setValue('approvedValue', StringUtils.removeMoneyFormat(value));
            }}
            rightIcon={<FiEdit2 size={18} color={color.primaryColor} />}
            activated={!disabledToEdit}
            width={100}
          />
          <InputText
            name="installmentValue"
            label={t('admin.customerAnalysis.tabDetails.installmentValue')}
            disabled
            defaultValue={StringUtils.moneyMaskPtbr(financing?.installmentValue ?? 0)}
            width={100}
          />
        </InputBox>
        <InputBox>
          <InputText
            name="installmentsTotal"
            label={t('admin.customerAnalysis.tabDetails.installmentsTotal')}
            disabled={disabledToEdit}
            defaultValue={t('admin.customerAnalysis.tabDetails.installment', { installment: financing?.installmentsTotal ?? 0 })}
            mask={disabledToEdit ? Mask.DEFAULT : Mask.NUMBERS}
            onBlur={() => {
              const value = methods.getValues('installmentsTotal');
              methods.setValue(
                'installmentsTotal',
                t('admin.customerAnalysis.tabDetails.installment', {
                  installment: value !== '' ? value : financing?.installmentsTotal ?? 0,
                })
              );
            }}
            onFocus={() => {
              const value = methods.getValues('installmentsTotal');
              methods.setValue('installmentsTotal', value.replace('parcelas', '').trim());
            }}
            rightIcon={<FiEdit2 size={18} color={color.primaryColor} />}
            activated={!disabledToEdit}
            width={100}
          />
          <InputText
            name="interestTaxReleased"
            mask={disabledToEdit ? Mask.DEFAULT : Mask.PERCENTAGE}
            label={t('admin.customerAnalysis.tabDetails.interestTaxReleased')}
            disabled={disabledToEdit}
            defaultValue={t('admin.customerAnalysis.tabDetails.tax', { tax: financing?.approvedTax ?? 0 })}
            onBlur={() => {
              const value = methods.getValues('interestTaxReleased');
              methods.setValue(
                'interestTaxReleased',
                t('admin.customerAnalysis.tabDetails.tax', {
                  tax: value !== '' ? value : financing?.approvedTax ?? 0,
                })
              );
            }}
            onFocus={() => {
              const value = methods.getValues('interestTaxReleased');
              methods.setValue('interestTaxReleased', value.replace('% a.m', '').trim());
            }}
            rightIcon={<FiEdit2 size={18} color={color.primaryColor} />}
            activated={!disabledToEdit}
            width={100}
          />
          <InputText
            name="financingTaxInitial"
            label={t('admin.customerAnalysis.tabDetails.financingTaxInitial')}
            disabled
            defaultValue={t('admin.customerAnalysis.tabDetails.tax', { tax: financing?.financingTax ?? 0 })}
            width={100}
          />
        </InputBox>

        <InputBox flexInit>
          <InputText
            name="financingTax"
            label={t('admin.customerAnalysis.tabDetails.CCBFees')}
            disabled
            defaultValue={t('admin.customerAnalysis.tabDetails.tax', { tax: financing?.monthly_tax ?? financing?.monthlyTax ?? 0 })}
            width={100}
          />
          <InputText
            name="cet"
            label={t('admin.customerAnalysis.tabDetails.cet')}
            disabled
            defaultValue={t('admin.customerAnalysis.tabDetails.tax', { tax: financing?.monthlyTotalEffectiveCost ?? 0 })}
            width={100}
          />
          <Labelinput>
            <div>
              <span>{t('simulatorFLow.simulator.loanGracePeriod')}</span>
            </div>
            <div style={{ maxWidth: '280px' }}>
              <SearchSelectInput
                name="loanGracePeriod"
                placeholder={t('register.placeholder.ownCar')}
                options={loanGracePeriodOptions
                  .sort((a, b) => a.days - b.days)
                  .map(item => ({
                    label: `${item.days} dias`,
                    value: item.id.toString(),
                  }))}
                defaultOptions={loanGracePeriodOptions
                  .sort((a, b) => a.days - b.days)
                  .map(item => ({
                    label: `${item.days} dias`,
                    value: item.id.toString(),
                  }))}
                defaultValue={t('admin.customerAnalysis.tabDetails.loanGracePeriodDays', {
                  days: financing?.firstDueDateInDays ? financing.firstDueDateInDays : '30',
                })}
                handleChange={values => {
                  setChosenLoanGracePeriod(values.value ?? '0');
                }}
                disabled={disabledToEdit}
                onBlur={() => {
                  const value = methods.getValues('loanGracePeriod');
                  methods.setValue(
                    'loanGracePeriod',
                    t('admin.customerAnalysis.tabDetails.loanGracePeriodDays', {
                      days: financing?.firstDueDateInDays ? financing.firstDueDateInDays : '30',
                    })
                  );
                }}
              />
            </div>
          </Labelinput>
        </InputBox>

        {ORGANIZATION_NAME === OrganizationName.EVOLVE && (
          <InputBox>
            <InputText
              name="retentionRate"
              label={t('admin.customerAnalysis.tabDetails.retentionRate')}
              disabled
              defaultValue={t('admin.customerAnalysis.tabDetails.percentageRetentionRate', {
                retentionRate: financing?.organizationSubsidiary?.retentionPercentage ?? 0,
              })}
              width={100}
            />
            <InputText
              name="masterRetentionValue"
              mask={disabledToEdit ? Mask.DEFAULT : Mask.PERCENTAGE}
              label={t('admin.customerAnalysis.tabDetails.masterRetentionValue')}
              disabled={disabledToEdit}
              defaultValue={t('admin.customerAnalysis.tabDetails.percentageRetentionRate', {
                retentionRate: financing?.retentionPercentage ?? 0,
              })}
              onBlur={() => {
                const value = methods.getValues('masterRetentionValue');
                methods.setValue(
                  'masterRetentionValue',
                  t('admin.customerAnalysis.tabDetails.percentageRetentionRate', {
                    retentionRate: value !== '' ? value : financing?.retentionPercentage ?? 0,
                  })
                );
              }}
              onFocus={() => {
                const value = methods.getValues('masterRetentionValue');
                methods.setValue('masterRetentionValue', value.replace('%', '').trim());
              }}
              rightIcon={<FiEdit2 size={18} color={color.primaryColor} />}
              activated={!disabledToEdit}
              width={100}
            />
            <div />
          </InputBox>
        )}

        <InputBox>
          {hasAuthorities(account?.authorities, [Authority.ROLE_ADMIN, Authority.ROLE_INTERNAL_ADMIN]) ? (
            <EditButtonContainer>
              {!loading && !disabledToEdit && (
                <EditButton
                  onClick={() => {
                    if (financing?.approvedValue) {
                      methods.clearErrors('approvedValue');
                      methods.setValue('approvedValue', StringUtils.moneyMaskPtbr(financing?.approvedValue));
                    }

                    if (financing?.financingTax) {
                      methods.clearErrors('financingTaxInitial');
                      methods.setValue(
                        'financingTaxInitial',
                        t('admin.customerAnalysis.tabDetails.tax', {
                          tax: financing?.financingTax ?? 0,
                        })
                      );
                    }
                    setDisabledToEdit(true);
                  }}
                  type="button"
                >
                  <span>{t('global.button.cancel')}</span>
                </EditButton>
              )}
              {disabledToEdit ? (
                <EditButton onClick={() => setDisabledToEdit(false)} type="button">
                  <FiEdit color={color.primaryColor} />
                  <span>{t('admin.customerAnalysis.tabDetails.edit')}</span>
                </EditButton>
              ) : (
                <EditButton onClick={methods.handleSubmit(saveEdit)}>
                  {loading ? <Loading /> : <span>{t('global.button.saveEdition')}</span>}
                </EditButton>
              )}
            </EditButtonContainer>
          ) : (
            <div />
          )}
        </InputBox>
      </FormProvider>
    </Container>
  );
};
