import { yupResolver } from '@hookform/resolvers/yup';
import { mapObjectArrayToReactSelectOption } from 'components/async-react-select-search';
import { ReactSelectOption, ReactSelectSearch } from 'components/react-select-search';
import InputText from 'components/styled-input';
import { TextInput } from 'components/text-input';
import { isDate, parse } from 'date-fns';
import { useOrganizationSubsidiaries } from 'hooks/use-organization-subsidiaries';
import _ from 'lodash';
import { FormProperties } from 'model/enums/form-properties';
import { MaritalStatus, SimulatorFormData } from 'model/landing-page';
import { Mask } from 'model/mask-types';
import React from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { genderType } from 'shared/util/select-utils';
import StringUtils from 'shared/util/string-utils';
import * as yup from 'yup';
import { SimulatorContainer, SimulatorTitle, StyledForm, Terms } from '../../style';
import SimulatorButton from '../form-button';

interface FormTertiaryProps {
  steps: string[];
  currentStep: number;
  setCurrentStep: (step: number) => void;
  setIsLoading: (value: number) => void;
  formData: SimulatorFormData;
  setFormData: (data: SimulatorFormData) => void;
  isLoading: number;
  sendApi;
  addressError?: string;
  isAdmin?: boolean;
  maritalStatusList?: MaritalStatus[];
}

const FormTertiary: React.FC<FormTertiaryProps> = ({
  steps,
  currentStep,
  setCurrentStep,
  setIsLoading,
  formData,
  setFormData,
  isLoading,
  sendApi,
  addressError,
  isAdmin = false,
  maritalStatusList,
}) => {
  const { t } = useTranslation();

  const hasGender = formData.gender && formData.gender !== '' ? true : false;
  const hasMaritalStatus = formData.maritalStatus?.id && formData.maritalStatus.id != null ? true : false;

  const { organizationSubsidiaries, findSubsidiaryByName } = useOrganizationSubsidiaries();

  const hasOrganizationSubsidiaries = formData.organizationSubsidiary?.id != null;

  const isValidSubsidiary = () => {
    return formData.organizationSubsidiary?.id != null;
  };

  const schema = yup.object().shape({
    [FormProperties.BIRTH_DATE]: yup
      .date()
      .transform((value, originalValue) => {
        const parsedDate = isDate(originalValue) ? originalValue : parse(originalValue, 'dd/MM/yyyy', new Date());
        return parsedDate;
      })
      .typeError(t('landingPage.openingScreen.simulatorForm.formValidations.invalidDate'))
      .required(t('landingPage.openingScreen.simulatorForm.formValidations.required')),
    [FormProperties.ZIP_CODE]: yup
      .string()
      .min(9, t('landingPage.openingScreen.simulatorForm.formValidations.invalidZipCode'))
      .required(t('landingPage.openingScreen.simulatorForm.formValidations.required')),
    [FormProperties.INCOME]: yup
      .number()
      .required(t('landingPage.openingScreen.simulatorForm.formValidations.required'))
      .test('test-income', t('landingPage.openingScreen.simulatorForm.formValidations.requiredMin100'), value => {
        return value && value >= 100 ? true : false;
      }),
    [FormProperties.GENDER]: yup
      .string()
      .transform((val, originalValue) => originalValue)
      .test('test-gender', t('landingPage.openingScreen.simulatorForm.formValidations.required'), value => hasGender),
    [FormProperties.MARITAL_STATUS]: yup
      .string()
      .test('test-marital-status', t('landingPage.openingScreen.simulatorForm.formValidations.required'), value => hasMaritalStatus),
    [FormProperties.ORGANIZATION_SUBSIDIARIES]: yup
      .string()
      .test(
        'test-organization-subsidiaries',
        t('landingPage.openingScreen.simulatorForm.formValidations.required'),
        value => hasOrganizationSubsidiaries
      )
      .test('test-organization-subsidiaries', t('landingPage.openingScreen.simulatorForm.formValidations.invalidSubsidiary'), value =>
        isValidSubsidiary()
      ),
  });
  const methods = useForm({ resolver: yupResolver(schema) });
  const isLast = currentStep === steps.length - 1;

  const handleForm = async () => {
    const isValid = await methods.trigger();
    const data = methods.getValues();
    if (!isValid) {
      return;
    }
    if (isLast) {
      sendApi(formData);
      setIsLoading(1);

      setTimeout(() => {
        setIsLoading(0);
      }, 2500);
    } else {
      setCurrentStep(currentStep + 1);
      setIsLoading(1);
    }
  };

  const formId = 'form-secondary';

  const handleSubmit = _.debounce(handleForm, 500);

  const handleChange = (value, key) => {
    setFormData({ ...formData, [key]: value });
  };

  const handleChangeOption = (name, value) => {
    setFormData({ ...formData, [name]: value });
  };

  const mapMaritalListNames = (): MaritalStatus[] => {
    return (
      maritalStatusList?.map(it => {
        return {
          ...it,
          id: it.id,
          displayValue: it.displayValue[0].toUpperCase() + it.displayValue.toLowerCase().substring(1),
        } as MaritalStatus;
      }) ?? []
    );
  };

  return (
    <>
      <SimulatorContainer>
        <SimulatorTitle>{t('landingPage.openingScreen.simulatorTitle')}</SimulatorTitle>
        <FormProvider {...methods}>
          <StyledForm
            onSubmit={e => {
              e.preventDefault();
              handleSubmit();
            }}
            name="LP - Form Completo"
            id={formId}
          >
            <InputText
              name={FormProperties.BIRTH_DATE}
              maxLength={10}
              isRequired
              label={t('landingPage.openingScreen.simulatorForm.inputLabels.birthDate')}
              onChange={e => handleChange(StringUtils.birthdayMask(e.target.value), FormProperties.BIRTH_DATE)}
              mask={Mask.BIRTHDAY}
            />
            <InputText
              name={FormProperties.ZIP_CODE}
              isRequired
              label={t('landingPage.openingScreen.simulatorForm.inputLabels.zipCode')}
              onChange={e => handleChange(e.target.value, FormProperties.ZIP_CODE)}
              mask={Mask.CEP}
              errorMessage={addressError !== '' ? addressError : undefined}
              maxLength={9}
            />
            <InputText
              name={FormProperties.INCOME}
              isRequired
              label={t('landingPage.openingScreen.simulatorForm.inputLabels.income')}
              onChange={e => handleChange(e.target.value, FormProperties.INCOME)}
              mask={Mask.CURRENCY}
            />
            <TextInput
              name={FormProperties.GENDER}
              label={t('landingPage.openingScreen.simulatorForm.inputLabels.gender')}
              isRequired
              hasCustomInput
            >
              <ReactSelectSearch
                name={FormProperties.GENDER}
                placeholder={''}
                options={genderType as ReactSelectOption[]}
                onChange={value => {
                  handleChangeOption(FormProperties.GENDER, value.value);
                }}
              />
            </TextInput>
            <TextInput
              name={FormProperties.MARITAL_STATUS}
              label={t('landingPage.openingScreen.simulatorForm.inputLabels.maritalStatus')}
              isRequired
              hasCustomInput
            >
              <ReactSelectSearch
                name={FormProperties.MARITAL_STATUS}
                placeholder={''}
                options={mapObjectArrayToReactSelectOption(mapMaritalListNames() ?? [], { label: 'displayValue', value: 'id' })}
                onChange={value => {
                  handleChangeOption(FormProperties.MARITAL_STATUS, { id: value.value });
                }}
              />
            </TextInput>
            <TextInput
              name={FormProperties.ORGANIZATION_SUBSIDIARIES}
              label={t('landingPage.openingScreen.simulatorForm.inputLabels.partner')}
              isRequired
              hasCustomInput
            >
              <ReactSelectSearch
                name={FormProperties.ORGANIZATION_SUBSIDIARIES}
                placeholder={''}
                options={mapObjectArrayToReactSelectOption(organizationSubsidiaries, { label: 'name', value: 'id' })}
                onChange={value => {
                  handleChangeOption(FormProperties.ORGANIZATION_SUBSIDIARIES, { id: value.value });
                }}
              />
            </TextInput>
          </StyledForm>
        </FormProvider>
        {!isAdmin && (
          <Terms>
            {t('landingPage.openingScreen.simulatorSlider.mensTerm')}
            <a href="https://esparta.s3.amazonaws.com/Termos+de+Uso+-+CreditFlow.pdf" target={'_blank'}>
              {t('landingPage.openingScreen.simulatorSlider.termsOfUse')}
            </a>
            {t('landingPage.openingScreen.simulatorSlider.and')}
            <a href="https://esparta.s3.amazonaws.com/Pol%C3%ADtica+de+Privacidade+-+CreditFlow.pdf" target={'_blank'}>
              {t('landingPage.openingScreen.simulatorSlider.privacyPolicy')}
            </a>
          </Terms>
        )}
        <SimulatorButton isLast={isLast} currentStep={currentStep} setCurrentStep={setCurrentStep} isLoading={isLoading} formId={formId} />
      </SimulatorContainer>
    </>
  );
};

export default FormTertiary;
