import { yupResolver } from '@hookform/resolvers/yup';
import _ from 'lodash';
import { FormProperties } from 'model/enums/form-properties';
import { SimulatorFormData } from 'model/landing-page';
import { useSelectLists } from 'provider/select-list';
import { useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';
import SimulatorButton from '../form-button';
import { SimulatorContainer, SimulatorText, SimulatorTitle, StyledForm, Terms } from '../../style';
import InputText from 'components/styled-input';
import { Mask } from 'model/mask-types';
import { TextInput } from 'components/text-input';
import { AsyncReactSelectSearch, mapObjectArrayToReactSelectOption } from 'components/async-react-select-search';

interface FormQuaternaryProps {
  steps: string[];
  currentStep: number;
  setCurrentStep: (step: number) => void;
  setIsLoading: (value: number) => void;
  formData: SimulatorFormData;
  setFormData: (data: SimulatorFormData) => void;
  initialForm: SimulatorFormData;
  isPhysicalPerson: boolean;
  isLoading: number;
  sendApi;
  addressError?: string;
  isAdmin?: boolean;
}

const FormQuaternary: React.FC<FormQuaternaryProps> = ({
  steps,
  currentStep,
  setCurrentStep,
  setIsLoading,
  formData,
  setFormData,
  initialForm,
  isPhysicalPerson,
  isLoading,
  sendApi,
  addressError,
  isAdmin = false,
}) => {
  const { t } = useTranslation();

  const { occupations, getOccupations, occupationsTotalPages, getMainActivities, mainActivities, mainActivitiesTotalPages } =
    useSelectLists();

  const schema = yup.object().shape({
    [FormProperties.ADDRESS_NUMBER]: yup.string().required(t('landingPage.openingScreen.simulatorForm.formValidations.required')),
    [FormProperties.ZIP_CODE]: yup
      .string()
      .min(9, t('landingPage.openingScreen.simulatorForm.formValidations.invalidZipCode'))
      .required(t('landingPage.openingScreen.simulatorForm.formValidations.required')),
    [FormProperties.INCOME]: yup.string().required(t('landingPage.openingScreen.simulatorForm.formValidations.required')),
    [FormProperties.OCCUPATION]: yup
      .string()
      .transform((val, originalValue) => originalValue)
      .test('test-occupation', occupationName => occupationName != null),
    [FormProperties.MAIN_ACTIVITY]: yup
      .string()
      .required(t('global.errorMessage.required'))
      .test('test-mainActivity', activityName => activityName != null),
    [FormProperties.REVENUE]: yup
      .string()
      .required(t('global.errorMessage.required'))
      .test('test-revenue', t('global.errorMessage.income'), revenue => {
        return Boolean(revenue?.match(/\d/g));
      }),
  });
  const methods = useForm({ resolver: yupResolver(schema) });
  const [page, setPage] = useState(1);
  const isLast = currentStep === steps.length - 1;

  const formfields = {
    ['physicalPerson']: [FormProperties.ADDRESS_NUMBER, FormProperties.ZIP_CODE, FormProperties.INCOME, FormProperties.OCCUPATION],
    ['legalPerson']: [FormProperties.ADDRESS_NUMBER, FormProperties.ZIP_CODE, FormProperties.REVENUE, FormProperties.MAIN_ACTIVITY],
  };

  const handleForm = async () => {
    const isValid = await methods.trigger(formfields[isPhysicalPerson ? 'physicalPerson' : 'legalPerson']);
    if (!isValid) {
      return;
    }
    if (isLast) {
      sendApi(formData);
      setIsLoading(1);

      setTimeout(() => {
        setIsLoading(0);
      }, 2500);
    } else {
      setCurrentStep(currentStep + 1);
      setIsLoading(1);
    }
  };

  const formId = 'form-secondary';

  const handleSubmit = _.debounce(handleForm, 500);

  const handleChange = (value, key) => {
    setFormData({ ...formData, [key]: value });
  };

  const handleChangeOption = (name, value) => {
    if (value) {
      setFormData({ ...formData, [name]: value });
    }
  };

  return (
    <>
      <SimulatorContainer>
        <SimulatorTitle>{t('landingPage.openingScreen.simulatorTitle')}</SimulatorTitle>
        <FormProvider {...methods}>
          <StyledForm
            onSubmit={e => {
              e.preventDefault();
              handleSubmit();
            }}
            name="LP - Form Completo"
            id={formId}
          >
            <InputText
              name={FormProperties.ZIP_CODE}
              isRequired
              label={t('landingPage.openingScreen.simulatorForm.inputLabels.zipCode')}
              onChange={e => handleChange(e.target.value, FormProperties.ZIP_CODE)}
              mask={Mask.CEP}
              errorMessage={addressError !== '' ? addressError : undefined}
              maxLength={9}
            />
            <InputText
              name={FormProperties.ADDRESS_NUMBER}
              isRequired
              label={t('landingPage.openingScreen.simulatorForm.inputLabels.addressNumber')}
              onChange={e => handleChange(e.target.value, FormProperties.ADDRESS_NUMBER)}
              mask={Mask.CEP}
              errorMessage={addressError !== '' ? addressError : undefined}
              maxLength={9}
            />

            {isPhysicalPerson ? (
              <InputText
                name={FormProperties.INCOME}
                isRequired
                label={t('landingPage.openingScreen.simulatorForm.inputLabels.income')}
                onChange={e => handleChange(e.target.value, FormProperties.INCOME)}
                mask={Mask.CURRENCY}
              />
            ) : (
              <InputText
                name={FormProperties.REVENUE}
                isRequired
                label={t('landingPage.openingScreen.simulatorForm.inputLabels.revenue')}
                onChange={e => handleChange(e.target.value, FormProperties.REVENUE)}
                mask={Mask.CURRENCY}
                maxLength={14}
              />
            )}
            {isPhysicalPerson ? (
              <TextInput
                name={FormProperties.OCCUPATION}
                label={t('landingPage.openingScreen.simulatorForm.inputLabels.occupation')}
                isRequired
                hasCustomInput
              >
                <AsyncReactSelectSearch
                  name={FormProperties.OCCUPATION}
                  placeholder=""
                  pageSize={30}
                  options={mapObjectArrayToReactSelectOption(occupations, { label: 'name', value: 'id' })}
                  search={getOccupations}
                  onChange={value => {
                    handleChangeOption(FormProperties.OCCUPATION, { id: value.value });
                  }}
                />
              </TextInput>
            ) : (
              <TextInput
                name={FormProperties.MAIN_ACTIVITY}
                label={t('landingPage.openingScreen.simulatorForm.inputLabels.mainActivity')}
                isRequired
                hasCustomInput
              >
                <AsyncReactSelectSearch
                  name={FormProperties.MAIN_ACTIVITY}
                  placeholder=""
                  pageSize={30}
                  options={mapObjectArrayToReactSelectOption(mainActivities, { label: 'name', value: 'id' })}
                  search={getMainActivities}
                  onChange={value => {
                    handleChangeOption(FormProperties.MAIN_ACTIVITY, { id: value.value });
                  }}
                />
              </TextInput>
            )}
          </StyledForm>
        </FormProvider>

        {!isAdmin && (
          <Terms>
            {t('landingPage.openingScreen.simulatorSlider.mensTerm')}
            <a href="https://esparta.s3.amazonaws.com/Termos+de+Uso+-+CreditFlow.pdf" target={'_blank'}>
              {t('landingPage.openingScreen.simulatorSlider.termsOfUse')}
            </a>
            {t('landingPage.openingScreen.simulatorSlider.and')}
            <a href="https://esparta.s3.amazonaws.com/Pol%C3%ADtica+de+Privacidade+-+CreditFlow.pdf" target={'_blank'}>
              {t('landingPage.openingScreen.simulatorSlider.privacyPolicy')}
            </a>
          </Terms>
        )}
        <SimulatorButton isLast={isLast} currentStep={currentStep} setCurrentStep={setCurrentStep} isLoading={isLoading} formId={formId} />
      </SimulatorContainer>
    </>
  );
};

export default FormQuaternary;
